import { Component, Input } from '@angular/core';
import { Org } from '@forgerock/backstage-ui-core';

@Component({
  selector: 'app-org-card',
  templateUrl: './org-card.component.html',
  styleUrls: [ './org-card.component.scss' ]
})
export class OrgCardComponent {
  @Input() org: Org;
}
