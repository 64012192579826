import { Component, OnInit } from '@angular/core';
import { Org, SubscriptionService } from '@forgerock/backstage-ui-core';
import { Observable } from 'rxjs';

import { PANEL_ANIMATION } from '../panel-animation';

@Component({
  selector: 'app-dashboard-support-panel',
  templateUrl: './dashboard-support-panel.component.html',
  styleUrls: [ './dashboard-support-panel.component.scss' ],
  animations: [ PANEL_ANIMATION ]
})
export class DashboardSupportPanelComponent implements OnInit {
  orgs: Observable<Org[]>;

  constructor(private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.orgs = this.subscriptionService.myOrgs;
  }

}
