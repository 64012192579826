<app-card>
  <header>{{org.name}}</header>
  <ul class="list-group list-group-flush">
    <li class="list-group-item bg-gray-200" *ngFor="let supportSet of org.supportSets">
      <a href="/account/subscriptions/{{supportSet.id}}" [class.strong]="supportSet.currentProductFamilies.length"
         class="text-ping-red fw-bold">{{supportSet.id}}</a>
      <div>{{supportSet.description}}</div>
      <div class="mt-1">
        <app-product-label *ngFor="let productFamily of supportSet.currentProductFamilies.sort()"
                           [productId]="productFamily" class="d-inline-block me-1"></app-product-label>
      </div>
    </li>
  </ul>
</app-card>
